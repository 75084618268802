import useLocale from '@hooks/useLocale';
import useTranslations from '@hooks/useTranslations';
import Card from '@molecules/Card/Card';
import Loader from '@molecules/Loader/Loader';
import Pagination, {
  Props as PaginationProps,
} from '@molecules/Pagination/Pagination';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import TextSection from '@organisms/TextSection/TextSection';
import { OverviewItem } from '@type-declarations/overviewItems';
import getInternalRoute from '@utils/getInternalRoute';
import clsx from 'clsx';
import { RefObject } from 'react';

import CaseItem from './CaseItem';
import EventItem from './EventItem';
import NewsItem from './NewsItem';

interface Props extends Partial<PaginationProps> {
  loading?: boolean;
  items: OverviewItem[];
  scrollToRef?: RefObject<HTMLDivElement>;
  notFoundTitle?: string;
  notFoundText?: string;
  title?: string;
  punchline?: string;
  text?: string;
  ctaLabel?: string;
  alternativeLayout?: boolean;
  hideIfEmpty?: boolean;
}

function CardGrid({
  title,
  punchline,
  text,
  ctaLabel,
  loading,
  items,
  scrollToRef,
  notFoundTitle,
  notFoundText,
  hasNextPage,
  alternativeLayout,
  hideIfEmpty,
  handleLoadMore,
}: Props) {
  const locale = useLocale();
  const t = useTranslations();

  if (!items.length && loading) {
    return <Loader loading={loading} modifier="verticalPadding" />;
  }

  if (!items.length && !hideIfEmpty) {
    return (
      <TextSection
        id="not-found-message"
        typename="TextSection"
        title={notFoundTitle || t.nothingFoundTitle}
        text={notFoundText || t.nothingFoundText}
        fullWidth
      />
    );
  }

  if (!items.length && hideIfEmpty) {
    return null;
  }

  return (
    <Section>
      <SectionContent
        fullWidth
        title={title}
        punchline={punchline}
        text={text}
        className="u-mb-6 u-mb-14@from-lg"
        alternativeLayout={alternativeLayout}
      />
      <div
        className={clsx(
          'u-grid u-grid--card-grid u-grid--2@sm u-grid--3@lg',
          loading && 'u-loading',
        )}
        ref={scrollToRef}
      >
        {items.map(item => {
          if (item.typename === 'NewsPage') {
            return <NewsItem key={item.id} item={item} />;
          }

          if (item.typename === 'CasePage') {
            return <CaseItem key={item.id} item={item} />;
          }

          if (item.typename === 'EventPage') {
            return <EventItem key={item.id} item={item} />;
          }

          return (
            <Card
              ctaLabel={ctaLabel}
              key={item.id}
              title={item.overviewInfo.title || item.title}
              image={item.overviewInfo.image}
              focusPoint={item.overviewInfo.focusPoint}
              href={getInternalRoute({
                internalLink: { page: [item] },
                locale,
              })}
            />
          );
        })}
      </div>

      {hasNextPage && handleLoadMore && (
        <Pagination
          hasNextPage={hasNextPage}
          handleLoadMore={handleLoadMore}
          loading={loading}
        />
      )}
    </Section>
  );
}

export default CardGrid;
